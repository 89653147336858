export const matchEntityCountries = [
    { code: "ZZ", label: "Global" },
    { code: "EU", label: "European Union" },
    { code: "ZR", label: "Zaire" },
    { code: "CZ", label: "Czech Republic" },
    { code: "XK", label: "Kosovo" },
    { code: "DD", label: "East Germany" },
    { code: "YUCS", label: "Yugoslavia" },
    { code: "CSXX", label: "Serbia and Montenegro" },
    { code: "CSHH", label: "Czechoslovakia" },
    { code: "SUHH", label: "Soviet Union" },
    { code: "ge-ab", label: "Abkhazia (Occupied Georgia)" },
    { code: "x-so", label: "South Ossetia (Occupied Georgia)" },
    { code: "ua-lpr", label: "Luhansk (Occupied Ukraine)" },
    { code: "ua-dpr", label: "Donetsk (Occupied Ukraine)" },
    { code: "ua-cri", label: "Crimea (Occupied Ukraine)" },
    { code: "so-som", label: "Somaliland" },
    { code: "cy-trnc", label: "Northern Cyprus" },
    { code: "az-nk", label: "Nagorno-Karabakh" },
    { code: "cn-xz", label: "Tibet" },
    { code: "gg-srk", label: "Sark" },
    { code: "gb-wls", label: "Wales" },
    { code: "gb-sct", label: "Scotland" },
    { code: "gb-nir", label: "Northern Ireland" },
    { code: "md-pmr", label: "Transnistria (PMR)" },
    { code: "AC", label: "Ascension Island" },
    { code: "AD", label: "Andorra" },
    { code: "AE", label: "United Arab Emirates" },
    { code: "AF", label: "Afghanistan" },
    { code: "AG", label: "Antigua & Barbuda" },
    { code: "AI", label: "Anguilla" },
    { code: "AL", label: "Albania" },
    { code: "AM", label: "Armenia" },
    { code: "AO", label: "Angola" },
    { code: "AQ", label: "Antarctica"},
    { code: "AR", label: "Argentina"},
    { code: "AS", label: "American Samoa"},
    { code: "AT", label: "Austria"},
    { code: "AU", label: "Australia"},
    { code: "AW", label: "Aruba"},
    { code: "AX", label: "Åland Islands"},
    { code: "AZ", label: "Azerbaijan"},
    { code: "BA", label: "Bosnia & Herzegovina"},
    { code: "BB", label: "Barbados"},
    { code: "BD", label: "Bangladesh"},
    { code: "BE", label: "Belgium"},
    { code: "BF", label: "Burkina Faso"},
    { code: "BG", label: "Bulgaria"},
    { code: "BH", label: "Bahrain"},
    { code: "BI", label: "Burundi"},
    { code: "BJ", label: "Benin"},
    { code: "BL", label: "St. Barthélemy"},
    { code: "BM", label: "Bermuda"},
    { code: "BN", label: "Brunei"},
    { code: "BO", label: "Bolivia"},
    { code: "BQ", label: "Caribbean Netherlands"},
    { code: "BR", label: "Brazil"},
    { code: "BS", label: "Bahamas"},
    { code: "BT", label: "Bhutan"},
    { code: "BV", label: "Bouvet Island"},
    { code: "BW", label: "Botswana"},
    { code: "BY", label: "Belarus"},
    { code: "BZ", label: "Belize"},
    { code: "CA", label: "Canada"},
    { code: "CC", label: "Cocos (Keeling) Islands"},
    { code: "CD", label: "Congo - Kinshasa"},
    { code: "CF", label: "Central African Republic"},
    { code: "CG", label: "Congo - Brazzaville"},
    { code: "CH", label: "Switzerland"},
    { code: "CI", label: "Côte d’Ivoire"},
    { code: "CK", label: "Cook Islands"},
    { code: "CL", label: "Chile"},
    { code: "CM", label: "Cameroon"},
    { code: "CN", label: "China"},
    { code: "CO", label: "Colombia"},
    { code: "CP", label: "Clipperton Island"},
    { code: "CQ", label: "Sark"},
    { code: "CR", label: "Costa Rica"},
    { code: "CU", label: "Cuba"},
    { code: "CV", label: "Cape Verde"},
    { code: "CW", label: "Curaçao"},
    { code: "CX", label: "Christmas Island"},
    { code: "CY", label: "Cyprus"},
    { code: "DE", label: "Germany"},
    { code: "DG", label: "Diego Garcia"},
    { code: "DJ", label: "Djibouti"},
    { code: "DK", label: "Denmark"},
    { code: "DM", label: "Dominica"},
    { code: "DO", label: "Dominican Republic"},
    { code: "DZ", label: "Algeria"},
    { code: "EA", label: "Ceuta & Melilla"},
    { code: "EC", label: "Ecuador"},
    { code: "EE", label: "Estonia"},
    { code: "EG", label: "Egypt"},
    { code: "EH", label: "Western Sahara"},
    { code: "ER", label: "Eritrea"},
    { code: "ES", label: "Spain"},
    { code: "ET", label: "Ethiopia"},
    { code: "EZ", label: "Eurozone"},
    { code: "FI", label: "Finland"},
    { code: "FJ", label: "Fiji"},
    { code: "FK", label: "Falkland Islands"},
    { code: "FM", label: "Micronesia"},
    { code: "FO", label: "Faroe Islands"},
    { code: "FR", label: "France"},
    { code: "GA", label: "Gabon"},
    { code: "GB", label: "United Kingdom"},
    { code: "GD", label: "Grenada"},
    { code: "GE", label: "Georgia"},
    { code: "GF", label: "French Guiana"},
    { code: "GG", label: "Guernsey"},
    { code: "GH", label: "Ghana"},
    { code: "GI", label: "Gibraltar"},
    { code: "GL", label: "Greenland"},
    { code: "GM", label: "Gambia"},
    { code: "GN", label: "Guinea"},
    { code: "GP", label: "Guadeloupe"},
    { code: "GQ", label: "Equatorial Guinea"},
    { code: "GR", label: "Greece"},
    { code: "GS", label: "South Georgia & South Sandwich Islands"},
    { code: "GT", label: "Guatemala"},
    { code: "GU", label: "Guam"},
    { code: "GW", label: "Guinea-Bissau"},
    { code: "GY", label: "Guyana"},
    { code: "HK", label: "Hong Kong SAR China"},
    { code: "HM", label: "Heard & McDonald Islands"},
    { code: "HN", label: "Honduras"},
    { code: "HR", label: "Croatia"},
    { code: "HT", label: "Haiti"},
    { code: "HU", label: "Hungary"},
    { code: "IC", label: "Canary Islands"},
    { code: "ID", label: "Indonesia"},
    { code: "IE", label: "Ireland"},
    { code: "IL", label: "Israel"},
    { code: "IM", label: "Isle of Man"},
    { code: "IN", label: "India"},
    { code: "IO", label: "British Indian Ocean Territory"},
    { code: "IQ", label: "Iraq"},
    { code: "IR", label: "Iran"},
    { code: "IS", label: "Iceland"},
    { code: "IT", label: "Italy"},
    { code: "JE", label: "Jersey"},
    { code: "JM", label: "Jamaica"},
    { code: "JO", label: "Jordan"},
    { code: "JP", label: "Japan"},
    { code: "KE", label: "Kenya"},
    { code: "KG", label: "Kyrgyzstan"},
    { code: "KH", label: "Cambodia"},
    { code: "KI", label: "Kiribati"},
    { code: "KM", label: "Comoros"},
    { code: "KN", label: "St. Kitts & Nevis"},
    { code: "KP", label: "North Korea"},
    { code: "KR", label: "South Korea"},
    { code: "KW", label: "Kuwait"},
    { code: "KY", label: "Cayman Islands"},
    { code: "KZ", label: "Kazakhstan"},
    { code: "LA", label: "Laos"},
    { code: "LB", label: "Lebanon"},
    { code: "LC", label: "St. Lucia"},
    { code: "LI", label: "Liechtenstein"},
    { code: "LK", label: "Sri Lanka"},
    { code: "LR", label: "Liberia"},
    { code: "LS", label: "Lesotho"},
    { code: "LT", label: "Lithuania"},
    { code: "LU", label: "Luxembourg"},
    { code: "LV", label: "Latvia"},
    { code: "LY", label: "Libya"},
    { code: "MA", label: "Morocco"},
    { code: "MC", label: "Monaco"},
    { code: "MD", label: "Moldova"},
    { code: "ME", label: "Montenegro"},
    { code: "MF", label: "St. Martin"},
    { code: "MG", label: "Madagascar"},
    { code: "MH", label: "Marshall Islands"},
    { code: "MK", label: "North Macedonia"},
    { code: "ML", label: "Mali"},
    { code: "MM", label: "Myanmar (Burma)"},
    { code: "MN", label: "Mongolia"},
    { code: "MO", label: "Macao SAR China"},
    { code: "MP", label: "Northern Mariana Islands"},
    { code: "MQ", label: "Martinique"},
    { code: "MR", label: "Mauritania"},
    { code: "MS", label: "Montserrat"},
    { code: "MT", label: "Malta"},
    { code: "MU", label: "Mauritius"},
    { code: "MV", label: "Maldives"},
    { code: "MW", label: "Malawi"},
    { code: "MX", label: "Mexico"},
    { code: "MY", label: "Malaysia"},
    { code: "MZ", label: "Mozambique"},
    { code: "NA", label: "Namibia"},
    { code: "NC", label: "New Caledonia"},
    { code: "NE", label: "Niger"},
    { code: "NF", label: "Norfolk Island"},
    { code: "NG", label: "Nigeria"},
    { code: "NI", label: "Nicaragua"},
    { code: "NL", label: "Netherlands"},
    { code: "NO", label: "Norway"},
    { code: "NP", label: "Nepal"},
    { code: "NR", label: "Nauru"},
    { code: "NU", label: "Niue"},
    { code: "NZ", label: "New Zealand"},
    { code: "OM", label: "Oman"},
    { code: "PA", label: "Panama"},
    { code: "PE", label: "Peru"},
    { code: "PF", label: "French Polynesia"},
    { code: "PG", label: "Papua New Guinea"},
    { code: "PH", label: "Philippines"},
    { code: "PK", label: "Pakistan"},
    { code: "PL", label: "Poland"},
    { code: "PM", label: "St. Pierre & Miquelon"},
    { code: "PN", label: "Pitcairn Islands"},
    { code: "PR", label: "Puerto Rico"},
    { code: "PS", label: "Palestinian Territories"},
    { code: "PT", label: "Portugal"},
    { code: "PW", label: "Palau"},
    { code: "PY", label: "Paraguay"},
    { code: "QA", label: "Qatar"},
    { code: "QO", label: "Outlying Oceania"},
    { code: "RE", label: "Réunion"},
    { code: "RO", label: "Romania"},
    { code: "RS", label: "Serbia"},
    { code: "RU", label: "Russia"},
    { code: "RW", label: "Rwanda"},
    { code: "SA", label: "Saudi Arabia"},
    { code: "SB", label: "Solomon Islands"},
    { code: "SC", label: "Seychelles"},
    { code: "SD", label: "Sudan"},
    { code: "SE", label: "Sweden"},
    { code: "SG", label: "Singapore"},
    { code: "SH", label: "St. Helena"},
    { code: "SI", label: "Slovenia"},
    { code: "SJ", label: "Svalbard & Jan Mayen"},
    { code: "SK", label: "Slovakia"},
    { code: "SL", label: "Sierra Leone"},
    { code: "SM", label: "San Marino"},
    { code: "SN", label: "Senegal"},
    { code: "SO", label: "Somalia"},
    { code: "SR", label: "Suriname"},
    { code: "SS", label: "South Sudan"},
    { code: "ST", label: "São Tomé & Príncipe"},
    { code: "SV", label: "El Salvador"},
    { code: "SX", label: "Sint Maarten"},
    { code: "SY", label: "Syria"},
    { code: "SZ", label: "Eswatini"},
    { code: "TA", label: "Tristan da Cunha"},
    { code: "TC", label: "Turks & Caicos Islands"},
    { code: "TD", label: "Chad"},
    { code: "TF", label: "French Southern Territories"},
    { code: "TG", label: "Togo"},
    { code: "TH", label: "Thailand"},
    { code: "TJ", label: "Tajikistan"},
    { code: "TK", label: "Tokelau"},
    { code: "TL", label: "Timor-Leste"},
    { code: "TM", label: "Turkmenistan"},
    { code: "TN", label: "Tunisia"},
    { code: "TO", label: "Tonga"},
    { code: "TR", label: "Türkiye"},
    { code: "TT", label: "Trinidad & Tobago"},
    { code: "TV", label: "Tuvalu"},
    { code: "TW", label: "Taiwan"},
    { code: "TZ", label: "Tanzania"},
    { code: "UA", label: "Ukraine"},
    { code: "UG", label: "Uganda"},
    { code: "UM", label: "U.S. Outlying Islands"},
    { code: "UN", label: "United Nations"},
    { code: "US", label: "United States"},
    { code: "UY", label: "Uruguay"},
    { code: "UZ", label: "Uzbekistan"},
    { code: "VA", label: "Vatican City"},
    { code: "VC", label: "St. Vincent & Grenadines"},
    { code: "VE", label: "Venezuela"},
    { code: "VG", label: "British Virgin Islands"},
    { code: "VI", label: "U.S. Virgin Islands"},
    { code: "VN", label: "Vietnam"},
    { code: "VU", label: "Vanuatu"},
    { code: "WF", label: "Wallis & Futuna"},
    { code: "WS", label: "Samoa"},
    { code: "XA", label: "Pseudo-Accents"},
    { code: "XB", label: "Pseudo-Bidi"},
    { code: "YE", label: "Yemen"},
    { code: "YT", label: "Mayotte"},
    { code: "ZA", label: "South Africa"},
    { code: "ZM", label: "Zambia"},
    { code: "ZW", label: "Zimbabwe"},
];