import React, { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Public from '../../service/Public';
import User from '../../service/User';
import Auth from "../../service/Auth";

const MultiStepForm = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        step1Data: {},
        step2Data: {},
        step3Data: {},
        step4Data: {}
    });
    
    const [apiResponse, setApiResponse] = useState(null);
    const [confirmation, setConfirmation] = useState(null);
    const [guestUserId, setGuestUserId] = useState(null);
    const paymentAmount = 15;

    const nextStep = async (data) => {
        if (currentStep === 3 && !guestUserId) {
            await saveFormData(data);
        }
        setCurrentStep(prevStep => prevStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(prevStep => prevStep - 1);
    };

    const handleChange = async (step, data) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [`step${step}Data`]: data
        }));
        if (step === 1) {
            await handleMatchEntity(data);
        }
    };

    const handleMatchEntity = async (data) => {
        try {
            const payload = {
                nationality: data.nationality || data.countryOfResidence,
                dataset: 'default',
                algorithm: 'logic-v1',
                name: data.formType === 'Person' ? `${data.firstName}${data.lastName}` : data.name,
                schema: data.formType,
                jurisdiction: data.jurisdiction || data.countryOfResidence,
                birthDate: data.dateOfBirth,
                gender: data.gender,
            };
            const response = await Public.matchEntity(payload);
            setFormData(prevFormData => ({
                ...prevFormData,
                step1Data: { ...data, matchData: response?.data?.data }
            }));
            setApiResponse("Data fetched successfully");
        } catch (error) {
            console.error("API call failed: ", error);
        }
    };

    const handleBuy = async (cardData) => {
        const { cardCvv, cardExp, cardNr, cardName, acceptTerms } = cardData;
        if (!acceptTerms) {
            return alert('Please confirm that you have read and accept our terms of service.');
        }
        const step3Data = formData.step3Data;
        setFormData(prevFormData => ({
            ...prevFormData,
            step4Data: cardData
        }));
        if (currentStep === 4) {
            try {
                const paymentResponse = await User.handleGuestPayment({
                    cardCvv,
                    cardExp,
                    cardNr,
                    cardName,
                    guestUserId,
                    email: step3Data.email,
                    name: step3Data.name,
                    country: step3Data.country,
                    amount: paymentAmount,
                });

                if (paymentResponse?.data?.message === 'Payment successful') {
                    const orderId = paymentResponse.data.orderNumber;
                    setApiResponse(paymentResponse.data);
                    setConfirmation();

                    await updateGuestUser({ paymentResponse: paymentResponse.data, orderId });

                    setCurrentStep(5);
                } else {
                    setConfirmation('Payment failed');
                }
            } catch (error) {
                console.error("Payment failed: ", error);
                setConfirmation('Payment failed');
            }
        }
    };

    const saveFormData = async (data) => {
        try {
            const payload = {
                ...formData,
                step3Data: data
            };
            const response = await Auth.guestUser(payload);
            setGuestUserId(response.data._id);
            setConfirmation();
        } catch (error) {
            console.error("Failed to save form data:", error);
            setConfirmation('Failed to save form data');
        }
    };

    const updateGuestUser = async ({ paymentResponse, orderId }) => {
        try {
            const data = { _id: guestUserId, paymentResponse, orderId };
            await Auth.updateGuestUser(data);
        } catch (error) {
            console.error("Failed to update guest user:", error);
        }
    };

    const handleViewScanReports = async () => {
        try {
            const response = await Auth.findGuestUserById(guestUserId);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            window.open(url);
        } catch (error) {
            console.error("Failed to view scan reports:", error);
        }
    };

    const handleDownloadReports = async () => {
        try {
            const response = await Auth.downloadGuestUserById(guestUserId);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'scan_report.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Failed to download scan reports:", error);
        }
    };

    const step1Data = formData.step1Data;
    const formType = step1Data.formType;
    const name = formType === 'Person' ? `${step1Data.firstName} ${step1Data.middleName} ${step1Data.lastName}` : step1Data.name;

    return (
        <div>
            {currentStep === 1 && <Step1 nextStep={nextStep} handleChange={handleChange} data={formData.step1Data} />}
            {currentStep === 2 && <Step2 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} data={formData.step2Data} formType={formType} name={name} />}
            {currentStep === 3 && <Step3 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} data={formData.step3Data} />}
            {currentStep === 4 && <Step4 prevStep={prevStep} handleBuy={handleBuy} data={formData.step4Data} confirmation={confirmation} setConfirmation={setConfirmation} />}
            {currentStep === 5 && <Step5 apiResponse={apiResponse} formData={formData} handleViewScanReports={handleViewScanReports} handleDownloadReports={handleDownloadReports} />}
        </div>
    );
};

export default MultiStepForm;
