import React from 'react';
import { Box, Button, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import Header from '../common/Header';
import Footer from '../common/Footer';

const Step5 = ({ formData, handleViewScanReports, handleDownloadReports }) => {
    const userEmail = formData.step3Data.email;
    const scanType = formData.step1Data.formType === 'Person' ? 'Person Scan' : 'Organisation Scan';

    return (
        <div>
            <div className="landing-wrapper">
                <Header />
                <div className="container">
                    <section className="bg-white dark:bg-gray-300">
                        <Container elevation={3} sx={{ p: 4, mt: 6 }}>
                            <Typography variant="h1" gutterBottom align="center" >
                                Pay As You Go
                            </Typography>
                            <Paper elevation={3} sx={{ padding: 4, textAlign: 'center', backgroundColor: 'grey.300' }}>
                                <Typography variant="h3" gutterBottom>
                                    THANK YOU FOR YOUR ORDER!
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Your invoice is been sent as confirmation of your order to <b>{userEmail}</b>
                                </Typography>
                                <Box sx={{ my: 4 }}>
                                    <Typography variant="body1" gutterBottom>
                                        Your report is ready. You can also view or download the report now.
                                    </Typography>
                                    <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                                        <Grid item>
                                            <Button className="button cc-small w-button" variant="contained" color="primary" onClick={handleDownloadReports}>
                                                Download Reports
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button className="button cc-small w-button" variant="contained" color="primary" onClick={handleViewScanReports}>
                                                View Scan Reports
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider sx={{ my: 4 }} />
                                <Box sx={{ my: 4 }}>
                                    <Typography variant="h6" gutterBottom>
                                        PRICE SUMMARY
                                    </Typography>
                                    <Typography variant="body1">
                                        {scanType}: $15.00
                                    </Typography>
                                </Box>
                                <Divider sx={{ my: 4 }} />
                                <Box sx={{ mt: 4 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Customer Service
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        If you have any questions or experience any issues, you can contact our customer service team using the <b>info@synrgy.solutions</b>.
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Customer Service hours are Monday to Friday, from 9:00 AM to 6:00 PM.
                                    </Typography>
                                </Box>
                            </Paper>
                        </Container>
                    </section>
                </div>
                <br />
                <br />
                <Footer />
            </div>
        </div>
    );
};

export default Step5;
