import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { message } from "antd";
import { logout } from "../redux/auth/actions";
import { store } from "../redux/store";

const middleField = (api) => {
  api.interceptors.response.use(
    (response) => {
      if (response?.data?.message) { response?.data?.matchedEntites !== false && message.success(response.data.message)};
      return response;
    },
    (error) => {
      const { response } = error;
      if (response) {
        const { data } = response;
        if (data && data.message) {
          if (
            data.message === "Invalid access_token" ||
            data.message?.toLowerCase?.() === "jwt must be provided"
          ) {
            store.dispatch(logout());
            window.location.href = "/";
            return;
          }

          message.error(data.message);
        }
        if (data && data.detail?.[0]?.msg) {
          message.error(data.detail?.[0]?.msg);
        }
      }
      return Promise.reject(error);
    }
  );

  api.interceptors.request.use(
    async (config) => config,
    (error) => Promise.reject(error)
  );
};

class Public {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  getPrivacy() {
    return this.api.get("/getPrivacy");
  }

  getTerms() {
    return this.api.get("/getTerms");
  }

  getCopyright() {
    return this.api.get("/getCopyright");
  }
  getOtherLegal() {
    return this.api.get("/getOtherLegal");
  }

  getHowItWorks() {
    return this.api.get("/getHowItWorks");
  }

  getAboutUs() {
    return this.api.get("/getAboutUs");
  }

  getSubscriptionPackages() {
    return this.api.get("/getSubscriptionPackages");
  }

  getHeroImage(key) {
    return this.api.get("/getHeroImage", { params: { key } });
  }
  getPricing() {
    return this.api.get("/getPricing");
  }
  getFAQs() {
    return this.api.get("/getFAQs");
  }
  getEmails() {
    return this.api.get("/getEmails");
  }
  searchEntity(params) {
    return this.api.get("/searchEntity", {
      params: {
        ...params,
        countries: `${params.countries}`,
        algorithm: "regression-v2",
      },
    });
  }
  matchEntity(params) {
    return this.api.get("/match", {
      params: {
        nationality: params.nationality,
        dataset: params.dataset || 'default',
        algorithm: 'logic-v1',
        name: params.name,
        schema: params.schema,
        jurisdiction: params.jurisdiction,
        birthDate: params.birthDate,
        gender: params.gender,
      },
    });
  }
  getOpenSanctionsCatalog(params) {
    return this.api.get("/getOpenSanctionsCatalog", { params });
  }
  contact({ name, email, subject, businessName, message }) {
    return this.api.post("/contact", {
      name,
      email,
      subject,
      businessName,
      message,
    });
  }

  getTutorials() {
    return this.api.get("/getTutorials");
  }
  getUsefulFiles() {
    return this.api.get("/getUsefulFiles");
  }
  getScreenShot() {
    return this.api.get("/getScreenshot");
  }
  postSearchResult({ query, name, relevance, recordId }) {
    return this.api.post("/store-result", {
      query,
      name,
      relevance,
      recordId,
    });
  }
  getSearchResultDb(record) {
    return this.api.get(`/getSearchResultDb/${record.caption}`);
  }
  getRelevantResult(params) {
    return this.api.get("/get-relevant-results", params);
  }
}

export default new Public(`${getBackendUrl()}/public`);
