import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormHelperText,
    TextField,
    Typography
} from '@mui/material';
import Header from '../common/Header';
import Footer from '../common/Footer';

const Step3 = ({ nextStep, prevStep, handleChange, data }) => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (data) {
            Object.keys(data).forEach(key => {
                setValue(key, data[key]);
            });
        }
    }, [data, setValue]);

    const onSubmit = async (formData) => {
        setIsSubmitting(true);
        await handleChange(3, { ...formData });
        nextStep(formData);
    };

    return (
        <div>
            <div className="landing-wrapper">
                <Header />
                <div className="container">
                    <section className="bg-white dark:bg-gray-300">
                        <Container elevation={3} sx={{ p: 4, mt: 6 }}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Typography variant="h1" gutterBottom align="center">
                                    Pay As You Go
                                </Typography>
                                <Typography variant="h2" gutterBottom>
                                    Guest User (Billing Information)
                                </Typography>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="First Name"
                                        variant="outlined"
                                        {...register('firstName', { required: "First Name is Required" })}
                                        error={!!errors.firstName}
                                        helperText={errors.firstName ? errors.firstName.message : ''}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Last Name"
                                        variant="outlined"
                                        {...register('lastName', { required: "Last Name is Required" })}
                                        error={!!errors.lastName}
                                        helperText={errors.lastName ? errors.lastName.message : ''}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Email Address"
                                        type="email"
                                        variant="outlined"
                                        {...register('email', { required: "Email Address is Required" })}
                                        error={!!errors.email}
                                        helperText={errors.email ? errors.email.message : ''}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Organization Name"
                                        variant="outlined"
                                        {...register('organization')}
                                        error={!!errors.organization}
                                        helperText={errors.organization ? errors.organization.message : ''}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Country"
                                        variant="outlined"
                                        {...register('country')}
                                        error={!!errors.country}
                                        helperText={errors.country ? errors.country.message : ''}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Contact Number"
                                        type="tel"
                                        variant="outlined"
                                        {...register('contactNumber')}
                                        error={!!errors.contactNumber}
                                        helperText={errors.contactNumber ? errors.contactNumber.message : ''}
                                    />
                                </FormControl>
                                <FormControl error={!!errors.termsAccepted} margin="normal">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...register('termsAccepted', { required: "You must accept the terms." })}
                                            />
                                        }
                                        label={
                                            <>
                                                <a href="/legal/terms" style={{ color: '#17183d', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                                                I HAVE READ & ACCEPT THE <span style={{ textDecoration: 'underline' }}>TERMS OF SERVICES</span>
                                                </a>
                                            </>
                                        }
                                    />
                                    <FormHelperText>{errors.termsAccepted ? errors.termsAccepted.message : ''}</FormHelperText>
                                </FormControl>
                                <Box>
                                    <Button onClick={prevStep} variant="contained" type="button" className="button cc-small w-button">
                                        Previous
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className="button cc-small w-button"
                                        sx={{ ml: 1 }}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Submitting...' : 'Continue to Payment'}
                                    </Button>
                                </Box>
                            </form>
                        </Container>
                    </section>
                </div>
                <br />
                <br />
                <Footer />
            </div>
        </div>
    );
}

export default Step3;
