import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer background-dark">
            <div className="container">
                <div className="footer-cta">
                    <div className="join">
                        <h2 className="display-3 text-white">
                            Start your AML journey today!
                        </h2>
                    </div>
                    <div className="div-block">
                        <Link to="/contact" className="button footerbutton w-button">
                            Say Hello
                        </Link>
                    </div>
                </div>
                <div className="divider" />
                <div className="footer-grid">
                    <div>
                        <img
                            src="/images/Logo_horizontal_white.png"
                            loading="lazy"
                            height
                            alt=""
                            width="220"
                            // srcSet="/images/Logo_stacked_white-p-500.png 500w, images/Logo_stacked_white-p-800.png 800w, images/Logo_stacked_white-p-1080.png 1080w, images/Logo_stacked_white-p-1600.png 1600w, images/Logo_stacked_white.png 1877w"
                            sizes="(max-width: 479px) 59vw, 119.49653625488281px"
                            className="logo margin-bottom-20"
                        />
                        <div className="margin-bottom-20">
                            © {new Date().getFullYear()} Synrgy Solutions.
                        </div>
                        <div className="social-links-wrapper">
                            <ul role="list" className="social-links w-list-unstyled">
                                <li className="social-link-item">
                                    <a
                                        href="https://www.linkedin.com/company/synrgyaml/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="social-link w-inline-block"
                                    >
                                        <div className="unicon"></div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="w-node-_11e2dc3d-d1e1-458c-44c2-5749cd8efbcf-cd8efba7">
                        <div className="address margin-bottom-20">Get In Touch</div>
                        <a
                            href="mailto:info@synrgy.solutions"
                            className="link-hover link-white"
                        >
                            <strong>info@synrgy.solutions</strong>
                        </a>
                    </div>
                    <div id="w-node-_11e2dc3d-d1e1-458c-44c2-5749cd8efbd8-cd8efba7">
                        <ul role="list" className="unordered-list margin-bottom-none">
                            <li>
                                <a href="#process" className="link-hover link-white">
                                    About
                                </a>
                            </li>
                            <li>
                                <Link to="/legal/privacy" className="link-hover link-white">
                                    Legals
                                </Link>
                            </li>
                            <li>
                                <a href="#howitworks" className="link-hover link-white">
                                    How it works
                                </a>
                            </li>
                            <li>
                                <a href="#pricing" className="link-hover link-white">
                                    Pricing
                                </a>
                            </li>
                            <li>
                                <Link to="/contact" className="link-hover link-white">
                                    Contact
                                </Link>
                            </li>
                            <li>
                                <Link to="/auth/login" className="link-hover link-white">
                                    Platform
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;