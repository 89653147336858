import { Link } from "react-router-dom";
import React, { useState } from "react";

const Header = () => {
    const [mobileMenu, setMobileMenu] = useState(false);

    return (
        <div className="navbar wf-section">
            <div className="navbar-space" />
            <div
                data-animation="default"
                data-collapse="medium"
                data-duration={400}
                data-easing="ease-out"
                data-easing2="ease-out"
                role="banner"
                className="navbar-inner w-nav"
            >
                <div className="container">
                    <div className="navbar-wrapper">
                        <nav role="navigation" className="nav-menu-wrapper w-nav-menu">
                            <div className="nav-menu-flex">
                                <Link to="/" className="w-nav-brand">
                                    <img
                                        src="/images/Logo_horizontal.png"
                                        loading="lazy"
                                        height
                                        alt=""
                                        width={357}
                                        srcSet="/images/Logo_horizontal-p-500.png 500w, images/Logo_horizontal-p-800.png 800w, images/Logo_horizontal-p-1080.png 1080w, images/Logo_horizontal-p-1600.png 1600w, images/Logo_horizontal-p-2000.png 2000w, images/Logo_horizontal-p-2600.png 2600w, images/Logo_horizontal.png 3056w"
                                        sizes="(max-width: 767px) 100vw, (max-width: 991px) 203.8715362548828px, (max-width: 1279px) 21vw, 18vw"
                                        className="logo"
                                    />
                                </Link>
                                <ul role="list" className="nav-menu w-list-unstyled">
                                    <li>
                                        <a href="#home" className="nav-link">
                                            Home
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#process" className="nav-link">
                                            About
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#howitworks" className="nav-link">
                                            How it works
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#pricing" className="nav-link">
                                            Pricing
                                        </a>
                                    </li>
                                </ul>
                                <Link to="/auth/login" style={{ marginRight: 20 }}>
                                    <img
                                        src="/images/Frame-10.png"
                                        loading="lazy"
                                        width={45}
                                        alt=""
                                        className="image-2"
                                    />
                                </Link>
                                <div className="navbar-button-wrapper">
                                    <Link to="/contact" className="button cc-small w-button">
                                        Say Hello
                                    </Link>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div
                data-collapse="medium"
                data-animation="default"
                data-duration={400}
                data-easing="ease-out"
                data-easing2="ease-out"
                data-w-id="577246c2-be80-9a0d-4fe9-018c566815d9"
                role="banner"
                className="navbar-inner-mobile w-nav"
            >
                <div className="container">
                    <div className="navbar-wrapper-mobile">
                        <Link
                            to="/"
                            aria-current="page"
                            className="navbar-brand-mobile w-nav-brand w--current"
                        >
                            <img
                                src="/images/Logo_horizontal.png"
                                loading="lazy"
                                height
                                width="119.5"
                                alt="flux"
                                className="logo"
                            />
                        </Link>
                        <div className="flex items-center">
                            <Link to="/auth/login" style={{ marginRight: 20 }}>
                                <img
                                    src="/images/Frame-10.png"
                                    loading="lazy"
                                    width={45}
                                    alt=""
                                    className="image-2"
                                />
                            </Link>
                            <div
                                data-w-id="577246c2-be80-9a0d-4fe9-018c566815de"
                                className="menu-button w-nav-button"
                                onClick={() => setMobileMenu((e) => !e)}
                            >
                                <div className="burger">
                                    <div className="burger-line cc-top" />
                                    <div className="burger-line cc-middle" />
                                    <div className="burger-line cc-bottom" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav
                        role="navigation"
                        className="nav-menu-wrapper-mobile w-nav-menu"
                        style={{ display: mobileMenu ? "unset" : "none" }}
                    >
                        <div className="nav-menu-flex-mobile">
                            <ul role="list" className="nav-menu w-list-unstyled">
                                <li>
                                    <a
                                        href="#home"
                                        className="nav-link-mobile"
                                        onClick={() => setMobileMenu(false)}
                                    >
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#process"
                                        className="nav-link-mobile"
                                        onClick={() => setMobileMenu(false)}
                                    >
                                        About
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#howitworks"
                                        className="nav-link-mobile"
                                        onClick={() => setMobileMenu(false)}
                                    >
                                        How it works
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#pricing"
                                        className="nav-link-mobile"
                                        onClick={() => setMobileMenu(false)}
                                    >
                                        Pricing
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="navbar-shadow" />
            <div className="navbar-space" />
        </div>
    )
}

export default Header;