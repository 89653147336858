import React, { useState, useEffect } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Grid } from '@mui/material';
import Header from '../common/Header';
import Footer from '../common/Footer';

function createData(item, price) {
    return { item, price };
}

const Step2 = ({ nextStep, prevStep, handleChange, data, formType, name }) => {
    const [rows, setRows] = useState([]);
    const [searchedName, setSearchedName] = useState('');

    useEffect(() => {
        if (formType === 'Organization') {
            setRows([createData('Organization Scan', 15)]);
        } else if (formType === 'Person') {
            setRows([createData('Person Scan', 15)]);
        }
    }, [formType]);

    useEffect(() => {
        setSearchedName(name);
    }, [name]);

    const handlePrevStep = () => {
        prevStep();
    };

    const handleNextStep = () => {
        nextStep();
    };

    return (
        <div>
            <div className="landing-wrapper">
                <Header />
                <div className="container">
                    <section className="bg-white dark:bg-gray-300">
                        <Container elevation={3} sx={{ p: 4, mt: 6 }}>
                            <Typography variant="h1" gutterBottom align="center" >
                                Pay As You Go
                            </Typography>
                            <Typography variant="h2" gutterBottom>
                                Order Details
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <TableContainer component={Paper}>
                                        <Table sx={{ backgroundColor: 'grey.200' }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ backgroundColor: 'grey.300', fontWeight: 'bold' }}>Item</TableCell>
                                                    <TableCell sx={{ backgroundColor: 'grey.300', fontWeight: 'bold' }} align="right">Price (USD)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell sx={{ backgroundColor: 'grey.200' }} component="th" scope="row">
                                                            {row.item}<br />
                                                            {searchedName}
                                                        </TableCell>
                                                        <TableCell sx={{ backgroundColor: 'grey.200' }} align="right">${row.price.toFixed(2)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <Box my={2}>
                                <Button onClick={handlePrevStep} variant="contained" type="submit" sx={{ mt: 2 }} className="button cc-small w-button">
                                    Previous
                                </Button>
                                <Button onClick={handleNextStep} variant="contained" type="submit" sx={{ mt: 2, ml: 1 }} className="button cc-small w-button">
                                    Continue Order
                                </Button>
                            </Box>
                        </Container>
                    </section>
                </div>
                <br />
                <br />
                <Footer />
            </div>
        </div>
    );
};

export default Step2;
